import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable, throwError} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';
import {InnerTube} from '../model/InnerTube';

@Injectable({
    providedIn: 'root'
})
export class InnertubeService {
    private url = environment.serverUrl + '/v1/innertube';

    constructor(private http: HttpClient) {}

    static handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        } else {
            console.log(error);
            console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
        }
        return throwError('Something bad happened; please try again later.');
    }


    getAll(valveType: string, bikeType: string, bikeSize: string, wheelWidth: string): Observable<InnerTube[]> {
        const options = { params : new HttpParams() };

        if (valveType) {
            options.params = options.params.append('valveType', valveType);
        }
        if (bikeType) {
            options.params = options.params.append('bikeType', bikeType);
        }
        if (bikeSize) {
            options.params = options.params.append('bikeSize', bikeSize);
        }
        if (wheelWidth) {
            options.params = options.params.append('wheelWidth', wheelWidth);
        }

        console.log(options);
        return this.http.get<InnerTube[]>(`${this.url}`, options)
            .pipe(retry(3))
            .pipe(catchError(InnertubeService.handleError));
    }

    findByCode(code: string): Observable<InnerTube[]> {
        const options = code ? { params : new HttpParams().set('code', code) } : {};
        return this.http.get<InnerTube[]>(`${this.url}/findByCode`, options)
            .pipe(retry(3))
            .pipe(catchError(InnertubeService.handleError));
    }

    search(q: string): Observable<InnerTube[]> {
        const options = q ? { params : new HttpParams().set('q', q) } : {};
        return this.http.get<InnerTube[]>(`${this.url}/search`, options)
            .pipe(retry(3))
            .pipe(catchError(InnertubeService.handleError));
    }
}
