import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from "./home/home.component";
import {SearchComponent} from "./search/search.component";
import {SearchResultComponent} from "./search_result/search_result.component";
import {NoResultComponent} from './no-result/no_result.component';


const routes: Routes = [
    {
        path: '',
        children: [
            {path: '', redirectTo: '/home', pathMatch: 'full'},
            {path: 'home', component: HomeComponent},
            {path: 'search', component: SearchComponent},
            {path: 'search-result', component: SearchResultComponent},
            {path: 'no-result', component: NoResultComponent},
            {path: '**', redirectTo: '/home', pathMatch: 'full'}
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
