import {Injectable} from '@angular/core';
import {interval} from 'rxjs/internal/observable/interval';
import {skipWhile} from 'rxjs/operators';
import {Subscription} from 'rxjs/internal/Subscription';

@Injectable({
    providedIn: 'root'
})
export class TimeoutService {

    constructor() {}

    registerNewTimeout(callback: (event: TimeoutEvent) => void, timeoutInS: number = 60, warningInS: number = 5): Subscription {
        const warn = (timeoutInS - warningInS);
        return interval(1000)
            .pipe(skipWhile(val => val < warn))
            .subscribe(val => {
                console.log('Timeout step ' + val);
                if (val === warn) {
                    callback(TimeoutEvent.TIMEOUT_WARNING);
                } else if (val === timeoutInS) {
                    callback(TimeoutEvent.TIMEOUT);
                }
            });
    }
}

export enum TimeoutEvent {
    TIMEOUT_WARNING,
    TIMEOUT
}