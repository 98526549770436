import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable, throwError} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';
import {FilterRequest} from '../model/FilterRequest';

@Injectable({
    providedIn: 'root'
})
export class WheelService {
    private url = environment.serverUrl + '/v1/wheel';

    constructor(private http: HttpClient) {}

    static handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        } else {
            console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
        }
        return throwError('Something bad happened; please try again later.');
    }

    getFiltersWidth(q: FilterRequest[]): Observable<string[]> {
        const jsonString = JSON.stringify(q);
        const options = q ? { params : new HttpParams().set('q', btoa(jsonString)) } : {};

        return this.http.get<string[]>(`${this.url}/filters`, options)
            .pipe(retry(3))
            .pipe(catchError(WheelService.handleError));
    }
}
