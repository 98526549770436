import {Component, HostListener, OnDestroy} from '@angular/core';
import {TimeoutEvent, TimeoutService} from '../services/timeout.service';
import {Subscription} from 'rxjs/internal/Subscription';
import {ActivatedRoute, Router} from '@angular/router';
import {InnertubeService} from '../services/innertube.service';
import {InnerTube} from '../model/InnerTube';


@Component({
    selector: 'app-search-result',
    templateUrl: './search_result.component.html',
    styleUrls: ['./search_result.component.css']
})
export class SearchResultComponent implements OnDestroy {
    private timeoutSubscription: Subscription;
    innertubes: InnerTube[];
    filters = [];

    bikeSize: string;
    bikeType: string;
    wheelWidth: string;
    valveType: string;

    constructor(public router: Router,
                private route: ActivatedRoute,
                private innertubeService: InnertubeService,
                public timeoutService: TimeoutService) {


        this.registerTimeout();

        // Set Filter Params
        this.route.params.subscribe(params => {
            const data = JSON.parse(params['data']);
            this.filters = data.filters;

            // Apply filter to Array
            if (this.filters.length === 0) { // No Filter, show everything
                this.loadInnerTube(null, null, null, null);
            } else if (this.filters.length === 1 && this.filters[0].label === 'Reference') { // Only one filter, so in our case, it's a search by reference
                this.search(this.filters[0].value);
            } else { // Search according to other filters
                for (const one of this.filters) {
                    console.log('Use Filter ' + one.label + ' with value ' + one.value);
                    switch (one.label) {
                        case 'Pratique': this.bikeType = one.value; break;
                        case 'Taille de la roue': this.bikeSize = one.value; break;
                        case 'Largeur': this.wheelWidth = one.value; break;
                        case 'Type de valve': this.valveType = one.value; break;
                        default: // Nothing to do
                    }
                }

                this.loadInnerTube(this.valveType, this.bikeType, this.bikeSize, this.wheelWidth);
            }
        });
    }

    private search(q: string) {
        console.log('search', q);
        this.innertubeService.search(q).subscribe(one => {
            console.log(one);
            if (one.length > 0) {
                this.innertubes = one;
            } else {
                this.router.navigate(['/no-result']);
            }
        });
    }

    private loadInnerTube(valveType: string, bikeType: string, bikeSize: string, wheelWidth: string) {
        this.innertubeService.getAll(valveType, bikeType, bikeSize, wheelWidth).subscribe(data => {
            if (data.length > 0) {
                this.innertubes = data;
            } else {
                this.router.navigate(['/no-result']);
            }
        });
    }

    private registerTimeout() {
        if (this.timeoutSubscription) {
            this.timeoutSubscription.unsubscribe();
        }
        this.timeoutSubscription = this.timeoutService.registerNewTimeout(event => {
            if (event === TimeoutEvent.TIMEOUT) {
                console.log('Timeout');
                this.router.navigate(['/home']);
            } else if (event === TimeoutEvent.TIMEOUT_WARNING) {
                console.log('warning');
            }
        });
    }

    @HostListener('document:click', ['$event'])
    public onDocumentClick(event: MouseEvent): void {
        // const targetElement = event.target as HTMLElement;
        console.log('Reset Timeout');
        this.registerTimeout();
    }

    ngOnDestroy() {
        console.log('SearchResult was destroyed');
        if (this.timeoutSubscription) {
            this.timeoutSubscription.unsubscribe();
        }
    }
}
