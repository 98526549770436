import {Component, HostListener, OnDestroy} from '@angular/core';
import {TimeoutEvent, TimeoutService} from '../services/timeout.service';
import {Subscription} from 'rxjs/internal/Subscription';
import {ActivatedRoute, Router} from '@angular/router';


@Component({
    selector: 'app-no-result',
    templateUrl: './no_result.component.html',
    styleUrls: ['./no_result.component.css']
})
export class NoResultComponent implements OnDestroy {
    private timeoutSubscription: Subscription;

    constructor(public router: Router,
                public timeoutService: TimeoutService) {
        this.registerTimeout();
    }

    private registerTimeout() {
        if (this.timeoutSubscription) {
            this.timeoutSubscription.unsubscribe();
        }
        this.timeoutSubscription = this.timeoutService.registerNewTimeout(event => {
            if (event === TimeoutEvent.TIMEOUT) {
                console.log('Timeout');
                this.router.navigate(['/home']);
            } else if (event === TimeoutEvent.TIMEOUT_WARNING) {
                console.log('warning');
            }
        });
    }

    @HostListener('document:click', ['$event'])
    public onDocumentClick(event: MouseEvent): void {
        // const targetElement = event.target as HTMLElement;
        console.log('Reset Timeout');
        this.registerTimeout();
    }

    ngOnDestroy() {
        console.log('NoResult was destroyed');
        if (this.timeoutSubscription) {
            this.timeoutSubscription.unsubscribe();
        }
    }
}