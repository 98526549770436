import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { APP_BASE_HREF } from '@angular/common';
import { SearchComponent } from './search/search.component';
import { SearchResultComponent } from './search_result/search_result.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NoResultComponent } from './no-result/no_result.component';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { HttpClientModule } from '@angular/common/http';
import {MatAutocompleteModule} from '@angular/material';

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        SearchComponent,
        SearchResultComponent,
        NoResultComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        NgbModule,
        NgxSmartModalModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
    ],
    providers: [
        {provide: APP_BASE_HREF, useValue: '/'}
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
