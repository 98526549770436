import { Component } from '@angular/core';
import {environment} from "../environments/environment";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor() {
    //Handle Google analytics dynamically
    if(environment.googleAnalyticsCode) {
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.googleAnalyticsCode;
      document.head.insertBefore(script, document.head.firstChild);
    }
  }
}
